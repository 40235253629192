import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, Form, Modal, OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime, IANAZone } from 'luxon';

import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete';
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';
import Timeline from '../../components/Timeline';
import InputList, { SelectConstEnabled } from '../../components/Common/inputList';

import { Show } from '../../hooks/Show';
import { useAuth } from '../../hooks/useAuth';
import { PermissionContext } from '../../hooks/PermissionContext';

import { ConvertToMoney } from '../../utils/formattingUtils';
import formatDateWithCustomFormat, { FormatsDate } from '../../utils/formatDate';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { HideByRole } from '../../utils/functionsUtils';
import { hasNoCountryLimitations } from '../../utils/functionsUtils';

const Swal = require('sweetalert2');

const Address = ({
  reduxGetClients,
  clients,
  reduxGetLogisticsOperators,
  logisticsOperators,
  reduxGetStatuses,
  statuses,
  reduxGetAddresses,
  addresses,
  reduxGetAddress,
  address,
  reduxPostAddress,
  rowEdited,
  reduxDeleteAddress,
  rowDeleted,
  reduxPatchAddress,
  rowUpdated,
  reduxResetAddressForm,
  reduxUploadFile,
  reduxResetAddressFormTrackingId,
  reduxUploadFileTrackingId,
  fileUploaded,
  reduxGetCities,
  cities,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxSetNeighborhood,
  reduxGetZoneNeighborhoods,
  reduxDisableAddress,
  rowDisabled,
  reduxGenerateGuide,
  addressWithGuide,
  reduxGenerateAllGuides,
  allGuides,
  reduxPatchAddressState,
  addressState,
  fileUploadedTrackingId,
  reduxResetAddressesEvents,
  errorsCreateAddress,
  errorsUpdateAddress,
  reduxPostBulkTrackingIdAddress,
  successfulCreateBulkTrackingIdAddress,
  bulkTrackingIdOperatorAddress,
  reduxResetBulkTrackingIdAddress,
  errorsCreateBulkTrackingIdAddress,
  reduxChangeTrackingIdAddressByOperator,
  reduxResetChangeTrackingIdAddressByOperator,
  successfulChangeTrackingIdAddressByOperatorAddress,
  errorsChangeTrackingIdAddressByOperatorAddress,
  addressWithChangeTrackingIdAddressByOperator,
  reduxGetBranches,
  branches,
  reduxDeleteAddresses,
  deleteSoftAddresses,
  reduxReverseAddresses,
  reversedAddresses,
  reduxReverseAddress,
  rowReversed,
  reduxUpdateAddressFile,
  reduxDownloadInvalidAddresses,
  successfulUploadInvalidAddresses,
  fetchInvalidAddressesData
}) => {
  const { hasPermission } = useContext(PermissionContext);

  const methods = useForm({
    defaultValues: {
      indicativeRoad: '',
      roadNumber: '',
      appendixRoad: '',
      crossoverRoad: '',
      crossoverNumber: '',
      appendixCrossingNumber: '',
      orientationCrossingNumber: '',
      insideTypes: '',
      doorNumber: '',
      inside: '',
      city: '',
      neighborhood: '',
      country: '',
      department: '',
      branch: '',
    },
  });
  const {
    register,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;
  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    watch: watchUpload,
    formState: { errors: errorsUpload },
    reset: resetUpload,
    setValue: setValueUpload,
    getValues: getValuesUpload,
  } = useForm({});


  const {
    register: registerUpdateAddress,
    handleSubmit: handleSubmitUpdateAddress,
    watch: watchUpdateAddress,
    formState: { errors: errorsUploadUpdateAddress },
    reset: resetUpdateAddress,
    setValue: setValueUpdateAddress,
    getValues: getValueUpdateAddress,
  } = useForm({});

  const {
    register: registerChangeOperator,
    handleSubmit: handleSubmitChangeOperator,
    watch: watchUploadChangeOperator,
    formState: { errors: errorsChangeOperator },
    reset: resetChangeOperator,
    setValue: setValueChangeOperator,
    getValues: getValuesChangeOperator,
  } = useForm({});

  const {
    register: registerUploadTrackingId,
    handleSubmit: handleSubmitUploadTrackingId,
    watch: watchUploadTrackingId,
    formState: { errors: errorsUploadTrackingId },
    reset: resetUploadTrackingId,
    setValue: setValueUploadTrackingId,
    getValues: getValuesUploadTrackingId,
  } = useForm({});

  const dispatch = useDispatch();

  const {
    register: registerChangeState,
    handleSubmit: handleSubmitChangeState,
    watch: watchChangeState,
    formState: { errors: errorsChangeState },
    reset: resetChangeState,
    setValue: setValueChangeState,
    getValues: getValuesChangeState,
  } = useForm({});

  const {
    control: downloadFormControl,
    formState: { errors: downloadFormErrors },
    handleSubmit: handleSubmitDownload,
    watch: downloadFormWatchValue,
    reset: resetDownloadForm,
    clearErrors: clearDownloadFormErrors,
  } = useForm({});

  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const countryUser = userData?.idCountry;
  const viewAllCountriesData = hasNoCountryLimitations();

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [cityFound, setCityFound] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [showUpdateAddress, setShowUpdateAddress] = useState(false);
  const [showUploadTrackingId, setShowUploadTrackingId] = useState(false);

  const [showChangeOperator, setShowChangeOperator] = useState(false);

  const [countryFound, setCountryFound] = useState('');
  const [departmentFound, setDepartmentFound] = useState('');
  const [neighborhoodFound, setNeighborhoodFound] = useState('');
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  
  const [fileNameUpdateAddress, setFileNameUpdateAddress] = useState(null);
  const [fileErrorUpdateAddress, setFileErrorUpdateAddress] = useState(null);

  const [fileNameTrackingId, setFileNameTrackingId] = useState(null);
  const [fileErrorTrackingId, setFileErrorTrackingId] = useState(null);

  const { session } = useAuth();

  const [showTimeline, setShowTimeline] = useState(false);
  const [idAddressSelected, setIdAddressSelected] = useState(null);
  const [showModalDates, setShowModalDates] = useState(false);
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [showChangeState, setShowChangeState] = useState(false);
  const [buttonType, setButtonType] = useState(null);
  const [checkGenerator, setCheckGenerator] = useState(false);
  const [valueManualAddress, setValueManualAddress] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [trackingIdsChangeOperator, setTrackingIdsChangeOperator] = useState(null);
  const [addressChangeOperator, setAddressChangeOperator] = useState(null);

  const indicativeRoad = useRef({});
  indicativeRoad.current = watch('indicativeRoad', '');

  const roadNumber = useRef({});
  roadNumber.current = watch('roadNumber', '');

  const appendixRoad = useRef({});
  appendixRoad.current = watch('appendixRoad', '');

  const crossoverRoad = useRef({});
  crossoverRoad.current = watch('crossoverRoad', '');

  const crossoverNumber = useRef({});
  crossoverNumber.current = watch('crossoverNumber', '');

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = watch('appendixCrossingNumber', '');

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = watch('orientationCrossingNumber', '');

  const insideTypes = useRef({});
  insideTypes.current = watch('insideTypes', '');

  const doorNumber = useRef({});
  doorNumber.current = watch('doorNumber', '');

  const inside = useRef({});
  inside.current = watch('inside', '');

  const neighborhood = useRef({});
  neighborhood.current = watch('idNeighborhood', '');

  const country = useRef({});
  country.current = watch('country', '');

  const department = useRef({});
  department.current = watch('department', '');

  const file = useRef({});
  file.current = watchUpload('file', '');

  const fileUpdateAddress = useRef({});
  fileUpdateAddress.current = watchUpdateAddress('file', '');

  const city = useRef({});
  city.current = watch('idCity', '');

  const state = useRef({});
  state.current = watch('state', '');

  const client = useRef({});
  client.current = watch('senderIds', '');

  const operator2 = useRef({});
  operator2.current = watch('operatorIds', '');

  const city2 = useRef({});
  city2.current = watch('idCity2', '');

  const fileTrackingId = useRef({});
  fileTrackingId.current = watchUploadTrackingId('file', '');
  
  const branch = useRef({});
  branch.current = watch('branch', '');

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
  };

  const CustomInput = ({ value, onClick, className }) => (
    <div className='input-group'>
      <input
        className={`form-control ${className}`}
        value={value}
        onClick={onClick}
        placeholder='dd/mm/yyyy'
      />
      <div className='input-group-prepend'>
        <button
          type='button'
          className='input-group-text'
          onClick={onClick}>
          <i className='fas fa-solid fa-calendar'></i>
        </button>
      </div>
    </div>
  );

  const handleOpenTimeline = (id) => {
    setIdAddressSelected(id);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    reduxResetAddressesEvents();
    setIdAddressSelected(null);
    setShowTimeline(false);
  };

  const handleClose = () => {
    reduxResetAddressForm();
    setShow(false);
  };

  const handleCloseUpload = () => {
    setFileName(null);
    resetUpload();
    reduxResetAddressForm();
    setFileError(null);
    setShowUpload(false);
  };

  const handleCloseUpdateAddress = () => {
    setFileNameUpdateAddress(null);
    resetUpdateAddress();
    reduxResetAddressForm();
    setFileErrorUpdateAddress(null);
    setShowUpdateAddress(false);
  };

  const handleCloseChangeOperator = () => {
    setShowChangeOperator(false);
    setAddressChangeOperator(null);
    setTrackingIdsChangeOperator(null);
    resetChangeOperator();
  };

  const handleCloseUploadTrackingId = () => {
    setFileNameTrackingId(null);
    resetUploadTrackingId();
    reduxResetAddressFormTrackingId();
    setFileErrorTrackingId(null);
    setShowUploadTrackingId(false);
  };

  const handleShow = () => {
    reduxResetAddressForm();
    resetUpload();
    setFileName(null);
    setShow(true);
    setCheckGenerator(false);
    setValueManualAddress('');
    reset();
  };

  const handleShowTrackingId = () => {
    reduxResetAddressFormTrackingId();
    resetUploadTrackingId();
    setFileNameTrackingId(null);
    setShowUploadTrackingId(true);
  };

  const handleShowUpload = () => {
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(true);
  };
  
  const handleShowUploadUpdateAddress = () => {
    setFileNameUpdateAddress(null);
    resetUpdateAddress();
    setFileErrorUpdateAddress(null);
    setShowUpdateAddress(true);
  };

  const lastItemWithStatus = (items) => {
    let returnTrackingId = ''
    items.forEach((item) => {
      if (item.returnTrackingId !== null && item.returnTrackingId !== '') {
        returnTrackingId = item.returnTrackingId;
      }
    })
    return returnTrackingId
  }
  
  const rescheduled = (items) => {
    let maxDate = null;
    items.forEach((item) => {
        if (item.date !== null && item.date !== '' && item.idNewState === 1) {
            const currentDate = new Date(item.date);
            if (maxDate === null || currentDate > maxDate) {
                maxDate = currentDate;
            }
        }
    });

    if (maxDate !== null) {
        const formattedDate = formatDate(maxDate);
        return formattedDate;
    } else {
        return ''; 
    }
};

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

  const columns = [
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true,
    },
    {
      title: 'ID externo',
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: 'trackingId',
      searchable: true,
    },
    {
      title: 'Estado',
      render: (rowData) => { return getStatusBadgeByCode(rowData.newState.code) },
      field: 'state',
      searchable: false,
    },
    {
      title: 'Guía de Retorno',
      render: (rowData) => {
        const returnTrackingId = rowData.returnTrackingId;
        return <span>{returnTrackingId}</span>;
      },
      field: 'returnTrackingId',
      searchable: true,
    },
    ...(hasPermission('feature:show-operator-address')
      ? [
        {
          title: 'Operador',
          render: (rowData) => {
            return <span>{rowData.operator ? rowData.operator.description : ''}</span>;
          },
          field: 'operator',
          searchable: false,
        },
      ]
      : []),
    {
      title: 'Destinatario',
      render: (rowData) => {
        return (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        );
      },
      field: 'firstName-lastName',
      searchable: true,
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address}</span>;
          },
          field: 'address',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Coordenadas',
          render: (rowData) => {
            return <span>{rowData.coordinates}</span>;
          },
          field: 'coordinates ',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-reference1-address')
      ? [
        {
          title: 'Teléfono Destinatario',
          render: (rowData) => {
            return (
              <span>{rowData.reference1}</span>
            );
          },
          field: 'reference1',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Observaciones',
      render: (rowData) => {
        return <span>{rowData.reference2}</span>;
      },
      field: 'reference2',
      searchable: true,
      visible: !HideByRole(session?.role.name)
    },
    {
      title: 'Valor a recaudar',
      render: (rowData) => {
        return <span>{ConvertToMoney(rowData.collection)}</span>;
      },
      field: 'collection',
      searchable: true,
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city.description : ''}</span>;
      },
      field: 'city.description',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => {
        return <span>{rowData.city && rowData.city.department ? rowData.city.department.description : ''}</span>;
      },
      field: 'city.department.description',
      searchable: true,
    },
    ...(hasPermission('feature:show-client-address')
      ? [
        {
          title: 'Remitente',
          render: (rowData) => {
            return <span>{rowData.company ? rowData.company.description : ''}</span>;
          },
          field: 'company.description',
          searchable: true,
        },
      ]
      : []),
    ...(hasPermission('feature:show-branch')
      ? [
        {
          title: 'Bodega',
          render: (rowData) => {
            return <span>{rowData.branch ? rowData.branch.branchName : ''}</span>;
          },
          field: 'branch.branchName',
          searchable: true,
        },
      ]
      : []),
      {
        title: 'Fecha de reprogramacion',
        render: (rowData) => {
          return <span>{rescheduled(rowData.addressRecords)}</span>;
        },
        field: 'rescheduledDate',
        searchable: true,
      },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                {rowData.deleted === 0 && (
                  <Show when='feature:generate-guide-pdf'>
                    <>
                      {rowData.trackingId && (
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 50, hide: 50 }}
                          overlay={renderTooltip('Descargar guía')}>
                          <button
                            title='Descargar guía'
                            className='btn btn-secondary btn-sm  btn-circle mr-2'
                            type='button'
                            onClick={(e) => generateGuidePDF(rowData.trackingId)}>
                            <i className='fas fa fa-download fa-xs'></i>
                          </button>
                        </OverlayTrigger>
                      )}
                    </>
                  </Show>
                )}
                {rowData.idOperator != null &&
                  rowData.deleted === 0 &&
                  (rowData.trackingId == null || rowData.trackingId == '') && (
                    <Show when='feature:edit-address'>
                      <button
                        title='Editar'
                        className='btn btn-primary btn-sm  btn-circle mr-2'
                        type='button'
                        onClick={(e) => handleOpen(rowData)}>
                        <i className='fas fa-edit fa-xs'></i>
                      </button>
                    </Show>
                  )}
                {rowData.deleted === 0 && (
                  <Show when='feature:see-timeline'>
                    <button
                      title='Timeline'
                      className='btn btn-primary btn-sm  btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleOpenTimeline(rowData.idAddress)}>
                      <i className='fas fa-info fa-xs'></i>
                    </button>
                  </Show>
                )}
                {rowData.deleted === 0 && (
                  <Show when='feature:disabled-address'>
                    <button
                      title='Desactivar'
                      className='btn btn-danger btn-sm btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleDisabled(rowData)}>
                      <i className='fas fa-times-circle fa-xs'></i>
                    </button>
                  </Show>
                )}
                {rowData.deleted === 0 && (
                  <Show when='feature:delete-address'>
                    <button
                      title='Eliminar'
                      className='btn btn-danger btn-sm btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleDelete(rowData)}>
                      <i className='fas fa-trash fa-xs'></i>
                    </button>
                  </Show>
                )}
                {rowData.deleted === 1 && (
                  <Show when='feature:delete-address'>
                    <button
                      title='Revertir'
                      className='btn btn-warning btn-sm btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleReverse(rowData)}>
                      <i className='fas fa-undo fa-xs'></i>
                    </button>
                  </Show>
                )}
                {(rowData.trackingId == null || rowData.trackingId == '') && rowData.deleted === 0 && (
                  <Show when='feature:can-show-checkGenerateGuide'>
                    <button
                      title='Generar guía'
                      className='btn btn-primary btn-sm btn-circle'
                      type='button'
                      onClick={(e) => handleGenerateGuide(rowData)}>
                      <i className='fas fa-barcode fa-xs'></i>
                    </button>
                  </Show>
                )}
              </>
            ) : (
              <>
                {rowData.deleted === 0 && (
                  <Show when='feature:enabled-address'>
                    <button
                      title='Activar'
                      className='btn btn-primary btn-sm  btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleActive(rowData)}>
                      <i className='fas fa-check-circle fa-xs'></i>
                    </button>
                  </Show>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleChangeMultiSelect = (type, value) => {
    if(type === 'Sender'){
      const newSenderIds = {
        ...getValues('senderIds'),
        value
      }
      setValue("senderIds", String(newSenderIds.value.map(item => item.value)));
    } else if(type === 'Operator'){
      const newOperatorIds = {
        ...getValues('operatorIds'),
        value
      }
      setValue("operatorIds", String(newOperatorIds.value.map(item => item.value)));
    }
  }

  const handleOpen = (row) => {
    reduxGetAddress({
      id: row.idAddress,
    });
    reset();
    setCheckGenerator(false);
    setValueManualAddress('');
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDisableAddress(row);
    }
  };

  const handleDisabled = (row) => {
    reduxDisableAddress(row);
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Está seguro de eliminar la dirección ' + row.idAddress + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        reduxDeleteAddress(row);
      }
    });
  };

  const handleReverse = (row) => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Está seguro de revertir la dirección ' + row.idAddress + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Reversar',
    }).then((result) => {
      if (result.isConfirmed) {
        reduxReverseAddress(row);
      }
    });
  };

  const searchByFilter = () => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (client.current) {
      filters = {
        ...filters,
        senderIds: client.current,
      };
    }
    if (operator2.current) {
      filters = {
        ...filters,
        operatorIds: operator2.current,
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current,
      };
    }
    if (branch.current) {
      filters = {
        ...filters,
        idBranch: branch.current,
      };
    } 
    if (startDateFrom && startDateTo) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, {
          format: FormatsDate.FULL24,
        }),
        to: formatDateWithCustomFormat(startDateTo, {
          format: FormatsDate.FULL24,
        }),
      };
    }
    reduxGetAddresses(filters);
  }

  const getOperatorId = (data) => {
    return hasPermission('feature:isOperator') ? session.role.idCompany : data.idOperator;
  };

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchAddress({
        ...data,
        id: rowEdited.idAddress,
        idOperator: getOperatorId(rowEdited),
        idNeighborhood: data.idNeighborhood && Number(data.idNeighborhood) > 0 ? Number(data.idNeighborhood) : null,
        idCity: city2.current != '' ? city2.current : rowEdited.idCity,
        address:
          getValues('address') && getValues('address').trim() != '' && checkGenerator
            ? getValues('address')
            : !checkGenerator && getValues('manualAddress') && getValues('manualAddress').trim() != ''
              ? getValues('manualAddress')
              : getValues('currentAddress'),
        neighborhoodNameReceiver: neighborhood.current ? neighborhoodFound : '',
        cityNameReceiver: city2.current ? cityFound : '',
        stateNameReceiver: department.current ? departmentFound : '',
        countryNameReceiver: country.current ? countryFound : '',
        boxedAddress: checkGenerator
          ? {
            indicativeRoad: data.indicativeRoad ? data.indicativeRoad : '',
            roadNumber: data.roadNumber ? data.roadNumber : '',
            appendixRoad: data.appendixRoad ? data.appendixRoad : '',
            crossoverRoad: data.crossoverRoad ? data.crossoverRoad : '',
            crossoverNumber: data.crossoverNumber ? data.crossoverNumber : '',
            appendixCrossingNumber: data.appendixCrossingNumber ? data.appendixCrossingNumber : '',
            orientationCrossingNumber: data.orientationCrossingNumber ? data.orientationCrossingNumber : '',
            doorNumber: data.doorNumber ? data.doorNumber : '',
            insideTypes: data.insideTypes ? data.insideTypes : '',
            inside: data.inside ? data.inside : '',
            country: data.country ? data.country : '',
            department: data.department ? data.department : '',
            city: data.city ? data.city : '',
            neighborhood: data.neighborhood ? data.neighborhood : '',
          }
          : null,
      });
    } else {
      reduxPostAddress({
        ...data,
        idNeighborhood: data.idNeighborhood && data.idNeighborhood > 0 ? Number(data.idNeighborhood) : null,
        idOperator: getOperatorId(data),
        idCity: Number(city2.current),
        neighborhoodNameReceiver: neighborhood.current ? neighborhoodFound : '',
        cityNameReceiver: city2.current ? cityFound : '',
        stateNameReceiver: department.current ? departmentFound : '',
        countryNameReceiver: country.current ? countryFound : '',
        address:
          getValues('address') && getValues('address').trim() != '' && checkGenerator
            ? getValues('address')
            : !checkGenerator && getValues('manualAddress') && getValues('manualAddress').trim() != ''
              ? getValues('manualAddress')
              : getValues('currentAddress'),
        boxedAddress: checkGenerator
          ? {
            indicativeRoad: data.indicativeRoad ? data.indicativeRoad : '',
            roadNumber: data.roadNumber ? data.roadNumber : '',
            appendixRoad: data.appendixRoad ? data.appendixRoad : '',
            crossoverRoad: data.crossoverRoad ? data.crossoverRoad : '',
            crossoverNumber: data.crossoverNumber ? data.crossoverNumber : '',
            appendixCrossingNumber: data.appendixCrossingNumber ? data.appendixCrossingNumber : '',
            orientationCrossingNumber: data.orientationCrossingNumber ? data.orientationCrossingNumber : '',
            doorNumber: data.doorNumber ? data.doorNumber : '',
            insideTypes: data.insideTypes ? data.insideTypes : '',
            inside: data.inside ? data.inside : '',
            country: data.country ? data.country : '',
            department: data.department ? data.department : '',
            city: data.city ? data.city : '',
            neighborhood: data.neighborhood ? data.neighborhood : '',
          }
          : null,
      });
    }
    reset();
    reduxResetAddressForm();
    setShow(false);
  };

  const onSubmitUpload = (data) => {
    const file = data.file[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;

        const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 1;

        if (!isCorrectDelimited) {
          return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
        } else {
          reduxUploadFile({
            file: file,
            company:
              session && session.role.idCompany
                ? session.role.idCompany
                : data.idCompanyModal,
            city: data.idCityModal ? data.idCityModal : "",
            idOperator: getOperatorId(data),
          });

          resetUpload();
          setFileName(null);
          reduxResetAddressForm();
          setFileError(null);
          setShowUpload(false);
        }
      };
    } else return Swal.fire('Archivo errado', 'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
  };

  const onSubmitUploadUpdateInvalidAddress = (data) => {
    const file = data.file[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;

        const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 1;

        if (!isCorrectDelimited) {
          return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
        } else {
          reduxUpdateAddressFile({
            file: file
          });

          resetUpdateAddress();
          setFileNameUpdateAddress(null);
          reduxResetAddressForm();
          setFileErrorUpdateAddress(null);
          setShowUpdateAddress(false);
        }
      };
    } else return Swal.fire('Archivo errado', 'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
  };

  const onSubmitChangeOperator = (data) => {
    if (trackingIdsChangeOperator != null && addressChangeOperator == null) {
      Swal.fire({
        title: 'Confirmación',
        text: ' ¿Está seguro que se desea cambiar el operador?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#21b5ea',
        cancelButtonColor: '#858796',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          reduxPostBulkTrackingIdAddress({
            addressesChecked: trackingIdsChangeOperator,
            idOperator: data.idOperator,
            idBranch: Number(data.idBranch),
          });
        }
      });
    }

    if (addressChangeOperator != null && trackingIdsChangeOperator == null) {
      const operator =
        logisticsOperators &&
        Object.keys(logisticsOperators).length > 0 &&
        logisticsOperators.items.filter((f) => f.idCompany == data.idOperator).shift();

      Swal.fire({
        title: 'Confirmación',
        text: `¿Está seguro que se desea cambiar a ${addressChangeOperator.operator.description}  por ${operator.description}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#21b5ea',
        cancelButtonColor: '#858796',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          reduxChangeTrackingIdAddressByOperator({
            idAddress: addressChangeOperator.idAddress,
            idOperator: data.idOperator,
            idBranch: Number(data.idBranch),
          });
        }
      });
    }

    setShowChangeOperator(false);
    resetChangeOperator();
  };

  const onSubmitUploadTrackingId = (data) => {
    const file = data.file[0];
    // if (file.current[0].type != "application/vnd.ms-excel") {
    //   setFileError("El archivo debe ser .csv");
    //   return;
    // }
    reduxUploadFileTrackingId({
      file: file,
    });

    resetUploadTrackingId();
    setFileNameTrackingId(null);
    reduxResetAddressFormTrackingId();
    setFileErrorTrackingId(null);
    setShowUploadTrackingId(false);
  };

  const handleGenerateGuide = (row) => {
    reduxGenerateGuide(row);
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setValue('addressesChecked', addresses ? addresses.items.map((f) => f.idAddress.toString()) : []);
    } else {
      setValue('addressesChecked', null);
    }
  };

  const generateAll = (e) => {
    const filterAddresses = addresses.items
      .filter(
        (a) => a.trackingId == null || a.trackingId == '' || (a.state == 1 && a.idOperator != null) || a.state == 2
      )
      .map((ele) => ele.idAddress);

    const objaddressesChecked = getValues('addressesChecked');
    const filterAddressesChecked = objaddressesChecked.filter((el) => filterAddresses.includes(Number(el)));

    if (filterAddressesChecked.length > 0) {
      reduxGenerateAllGuides({
        addressesChecked: typeof filterAddressesChecked == 'string' ? [filterAddressesChecked] : filterAddressesChecked,
      });
    } else {
      Swal.fire(
        'Información',
        'Debe seleccionar todas las direcciones a las que se les generará el número de guía',
        'info'
      );
    }
  };

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    if (!date) {
      return setStartDateTo(date);
    }
    const toDate = new Date(date);
    toDate.setHours(23, 59, 59, 0);
    setStartDateTo(toDate);
  };

  const handledChangeTo = (date) => {
    setStartDateTo(date);
  };

  const openModal = () => {
    if (getValues('addressesChecked') && getValues('addressesChecked').length > 0) {
      setShowChangeState(true);
      resetChangeState();
    } else {
      Swal.fire('Información', 'Debe seleccionar todas las direcciones que desea procesar', 'info');
    }
  };

  const handleCloseChangeState = () => {
    reduxResetAddressForm();
    setShowChangeState(false);
  };

  const onSubmitChangeState = async (data) => {
    const addressesChecked = getValues('addressesChecked');

    const filterAddresses = addresses.items
      .filter((a) => a.trackingId == null || a.trackingId == '' || a.idOperator != null)
      .map((ele) => ele.idAddress);

    const filterAddressesChecked = addressesChecked.filter((el) => filterAddresses.includes(Number(el)));

    if (data.stateChangeState) {
      reduxPatchAddressState({
        addressesChecked: typeof filterAddressesChecked == 'string' ? [filterAddressesChecked] : filterAddressesChecked,
        state: data.stateChangeState,
        txtNote: data.noteChangeState ? data.noteChangeState : undefined,
      });
    } else {
      Swal.fire('Información', 'Debe seleccionar el nuevo estado', 'info');
    }
  };

  const openModalDates = (type) => {
    setShowModalDates(true);
    resetDownloadForm({
        endDate: null,
        startDate: null,
        senders: null,
        country: null,
        operator: null,
      });
    setButtonType(type);
  };

  const handleDownload = (data) => {
    Swal.fire(
      'Información',
      'El proceso puede tardar un poco... al finalizar se abrirá el Excel con la información.',
      'info'
    ).then((res) => {
      switch (buttonType) {
        case 1:
          handleShowDownload(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 2:
          handleShowDownloadAddressWithoutGuide(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 3:
          handleShowDownloadHistory(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        case 4:
          handleShowDownloadInvalidAddresses(data);
          dispatch({
            type: 'SHOW_LOADING',
          });
          break;
        default:
          break;
      } 
    });

  };

  const handleShowDownload = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      idCompany: session.role.idCompany,
      role: session.role.idRole,
      start: formatDateWithCustomFormat(data.startDate, {
        format: 'YYYY-MM-DD 00:00:00',
      }),
      end: formatDateWithCustomFormat(data.endDate, {
        format: 'YYYY-MM-DD 23:59:59',
      }),
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/download`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-errors-addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadAddressWithoutGuide = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      idCompany: session.role.idCompany,
      start: formatDateWithCustomFormat(data.startDate, {
        format: 'YYYY-MM-DD 00:00:00',
      }),
      end: formatDateWithCustomFormat(data.endDate, {
        format: 'YYYY-MM-DD 23:59:59',
      }),
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });
    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/downloadAddressWithoutGuide`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-incomplete-addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadHistory = (data) => {
    const token = window.localStorage.getItem('token');
    dispatch({
      type: 'HIDE_LOADING',
    });
    let _data = {
      role: session.role.idRole,
      idUser: session.idUser,
      start: formatDateWithCustomFormat(data.startDate, {
        format: FormatsDate.FULL24,
      }),
      end: formatDateWithCustomFormat(data.endDate, { format: FormatsDate.FULL24 }),
      senders: data.senders && String(data.senders.map(item => item.value)),
      country: data.country && data.country.label,
      operator: data.operator && data.operator.value,
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });

    clearDownloadFormErrors();

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/addresses/downloadAllAddresses`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          window.localStorage.removeItem('token');
          Swal.fire({
            icon: 'info',
            title: 'Token Expired',
            text: 'Your session has expired. Please log in again.',
          }).then(() => {
            window.location.href = '/';
          });
          return;
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + 'addresses.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch({
          type: 'HIDE_LOADING',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const handleShowDownloadInvalidAddresses = (data) => {
    let _data = {
      start: formatDateWithCustomFormat(data.startDate, {
        format: FormatsDate.FULL24,
      }),
      end: formatDateWithCustomFormat(data.endDate, { format: FormatsDate.FULL24 })
    };

    resetDownloadForm({
      endDate: null,
      startDate: null,
      senders: null,
      country: null,
      operator: null,
    });

    clearDownloadFormErrors();
    reduxDownloadInvalidAddresses(_data)
  };

  const generateGuidePDF = async (trackingId) => {
    dispatch({
      type: 'SHOW_LOADING',
    });

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/pdf-tracking/generateGuidePDF/${trackingId}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.blob();
        }

        if (response.status === 401) {
          window.localStorage.removeItem('token');
          return;
        }

        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      })
      .then((blob) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd')}-${trackingId}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        dispatch({
          type: 'HIDE_LOADING',
        });
        console.log(err);
      });
  };

  const bulkTrackingIdAddressByOperator = async (event) => {
    Swal.fire({
      title: 'Estimado usuario',
      text: "Solo se cambiará el operador a las órdenes que están en estado 'Asignado a un operador'.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cerrar',
    }).then((result) => {
      if (result.isConfirmed) {
        const objaddressesChecked = getValues('addressesChecked');
        const filterAddresses = addresses.items.filter(
          (el) => objaddressesChecked.includes(String(el.idAddress)) && el.state == 2
        );
        const trackingIds = filterAddresses.map((el) => el.idAddress);
        setTrackingIdsChangeOperator(trackingIds);
        setShowChangeOperator(true);
      }
    });
  };

  const deleteAddresses = async () => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Está seguro de eliminar las órdenes seleccionadas?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        const objaddressesChecked = getValues('addressesChecked');
        const addressIds = objaddressesChecked.map((add) => Number(add));
        reduxDeleteAddresses(addressIds);
      }
    });
  };

  const reverseAddresses = async () => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Está seguro de revertir de las órdenes seleccionadas?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Reversar',
    }).then((result) => {
      if (result.isConfirmed) {
        const objaddressesChecked = getValues('addressesChecked');
        const addressIds = objaddressesChecked.map((add) => Number(add));
        reduxReverseAddresses(addressIds);
      }
    });
  };

  const changeTrackingIdAddressByOperator = async (rowData) => {
    if (rowData.state != 2) {
      Swal.fire(
        'Estimado usuario',
        "No se puede realizar el cambio de operador. Las órdenes deben estar en estado 'Asignado a un operador'.",
        'error'
      );
      return;
    } else {
      setValue('addressesChecked', null);
      setValue('all', null);
      setShowChangeOperator(true);
      setTrackingIdsChangeOperator(null);
      setAddressChangeOperator(rowData);
    }
  };

  const generateAllTrackingIdPdfByKikilogistics = async (event) => {
      dispatch({
        type: 'SHOW_LOADING',
      });

      const objaddressesChecked = getValues('addressesChecked');
      const filterAddresses = addresses.items.filter((el) => objaddressesChecked.includes(String(el.idAddress)));
      const trackingIds = filterAddresses.map((el) => el.trackingId);

      fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/pdf-tracking/generateAllGuidePDF/`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          trackingIds: trackingIds,
          filterAddresses,filterAddresses
          // operatorIds: operator2.current,
        }),
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {

            return response.json()
          }

          if (response.status === 401) {
            window.localStorage.removeItem('token');
            return;
          }

          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        })
        .then((data) => {
          dispatch({
            type: 'HIDE_LOADING',
          });
          const { buffedInput, successCount,errorCount } = data;
          const url = window.URL.createObjectURL(new Blob([Uint8Array.from(buffedInput.data)]));
          let a = document.createElement('a');
          a.href = url;
          a.download = `${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd')}-${Date.now()}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();

          Swal.fire({
            title: 'Descarga completada',
            html: `El archivo PDF se ha descargado exitosamente.\n Cantidad de órdenes exitosas: ${successCount} <br> Cantidad de órdenes erroneas: ${errorCount} `,
            icon: 'success',
          });


        })
        .catch((err) => {
          dispatch({
            type: 'HIDE_LOADING',
          });
          console.log(err);
        });
  };

  const checkOption = (e) => {
    Swal.fire({
      title: 'Advertencia',
      text: 'Al dar click en esta opción se borrarán los datos anteriormente diligenciados para dirección. ¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#21b5ea',
      cancelButtonColor: '#858796',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        resetGenerator();
        setValue('manualAddress', '');
        setValue('manual', '');
        setValue('country', null);
        setValue('department', '');
        setValue('idCity2', '');
        setValue('idNeighborhood', '');
        reduxSetDepartment();
        reduxSetCity();
        reduxSetNeighborhood();
        setCheckGenerator(e.target.checked);
      } else {
        setValue('generateAddress', !e.target.checked);
      }
    });
  };

  const resetGenerator = () => {
    setValue('indicativeRoad', '');
    setValue('roadNumber', '');
    setValue('appendixRoad', '');
    setValue('crossoverRoad', '');
    setValue('crossoverNumber', '');
    setValue('appendixCrossingNumber', '');
    setValue('orientationCrossingNumber', '');
    setValue('insideTypes', '');
    setValue('doorNumber', '');
    setValue('inside', '');
  };

  useEffect(() => {
    if (addressState) {
      setShowChangeState(false);
      resetChangeState();
      Swal.fire(
        'Proceso exitoso!',
        (addressState.total == 1
          ? 'Se modificó el estado de ' + addressState.total + ' dirección '
          : 'Se modificó el estado de ' + addressState.total + ' direcciones ') +
        'al estado ' +
        addressState.state,
        'success'
      );
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      reduxGetAddresses(filters);
      reduxResetAddressForm();
      setValue('addressesChecked', null);
      setValue('all', false);
      reset();
    }
  }, [addressState]);

  useEffect(() => {
    if (cities.items && Object.keys(cities.items).length > 0) {
      const options = cities.items
        .filter((city) => city.isActive)
        .map((city) => ({
          value: city.idCity,
          label: city.description,
        }));
      setCityOptions(options);
    } else {
      setCityOptions([]);
    }
  }, [cities]);

  useEffect(() => {
    if (file.current != null && file.current.length > 0) {
      setFileName(file.current[0].name);
    }
  }, [file.current]);

  useEffect(() => {
    if (fileUpdateAddress.current != null && fileUpdateAddress.current.length > 0) {
      setFileNameUpdateAddress(fileUpdateAddress.current[0].name);
    }
  }, [fileUpdateAddress.current]);

  useEffect(() => {
    if (fileTrackingId.current != null && fileTrackingId.current.length > 0) {
      setFileNameTrackingId(fileTrackingId.current[0].name);
    }
  }, [fileTrackingId.current]);

  useEffect(() => {
    if (rowReversed && rowReversed.message == undefined) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      if (city.current) {
        filters = {
          ...filters,
          idCity: city.current,
        };
      }
      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      reduxGetAddresses(filters);
      setValue('addressesChecked', null);
      setValue('all', false);
      reduxResetAddressForm();
      Swal.fire('Proceso exitoso!', 'La dirección fue reversada con éxito.', 'success');
    }
  }, [rowReversed]);

  useEffect(() => {
    if (rowDeleted && rowDeleted.message == undefined) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      reduxGetAddresses(filters);
      setValue('addressesChecked', null);
      setValue('all', false);
      reduxResetAddressForm();
      Swal.fire('Proceso exitoso!', 'La dirección fue eliminada con éxito.', 'success');
    }
  }, [rowDeleted]);

  useEffect(() => {
    reduxGetAddresses({
      page: 1,
      offset: offset,
      search: '',
    });
    reduxGetClients();
    reduxGetLogisticsOperators();
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
    reduxGetZoneNeighborhoods({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    reduxGetCities({
      page: 1,
      search: '',
      offset: 100000,
      isActive: true,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
    setValue('addressesChecked', null);
    setValue('all', false);
    reduxResetAddressForm();
    reset();
    resetDownloadForm({
        endDate: null,
        startDate: null,
        senders: null,        
        country: null,
        operator: null,
      });
    fileUploaded = null;
    rowEdited = null;
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
  }, []);

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue('trackingId', rowEdited.trackingId);
      setValue('firstName', rowEdited.firstName);
      setValue('lastName', rowEdited.lastName);
      setValue('currentAddress', rowEdited.address);
      setValue('reference1', rowEdited.reference1);
      setValue('reference2', rowEdited.reference2);
      setValue('clientTrackingId', rowEdited.clientTrackingId);
      setValue('declaredValue', rowEdited.declaredValue);
      setValue('idCompany', rowEdited.idCompany);
      setValue('idOperator', rowEdited.idOperator);
      setValue('senderPhone', rowEdited.senderPhone);
      setValue('senderEmail', rowEdited.senderEmail);
      setValue('externalId', rowEdited.externalId);
      setValue('deliveryDate', DateTime.fromJSDate(rowEdited.deliveryDate).setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd'));
      setValue('rescheduledDate', DateTime.fromJSDate(rowEdited.rescheduledDate).setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd'));
      rowEdited.countryNameReceiver &&
        setValue(
          'country',
          countries.items.filter((co) => co.description === rowEdited.countryNameReceiver)[0].idCountry
        );
      if (rowEdited.boxedAddress != null) {
        setCheckGenerator(true);
        reduxGetDepartmentsByCountry({
          idCountry: Number(rowEdited.boxedAddress.country),
        });
        setValue('indicativeRoad', rowEdited.boxedAddress.indicativeRoad);
        setValue('roadNumber', rowEdited.boxedAddress.roadNumber);
        setValue('appendixRoad', rowEdited.boxedAddress.appendixRoad);
        setValue('crossoverRoad', rowEdited.boxedAddress.crossoverRoad);
        setValue('crossoverNumber', rowEdited.boxedAddress.crossoverNumber);
        setValue('appendixCrossingNumber', rowEdited.boxedAddress.appendixCrossingNumber);
        setValue('orientationCrossingNumber', rowEdited.boxedAddress.orientationCrossingNumber);
        setValue('doorNumber', rowEdited.boxedAddress.doorNumber);
        setValue('insideTypes', rowEdited.boxedAddress.insideTypes);
        setValue('inside', rowEdited.boxedAddress.inside);
        setValue('country', rowEdited.boxedAddress.country);
      } else {
        setCheckGenerator(false);
        setValueManualAddress(rowEdited.address);
      }
    }
  }, [rowEdited]);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (client.current) {
      filters = {
        ...filters,
        senderIds: client.current,
      };
    }
    if (operator2.current) {
      filters = {
        ...filters,
        operatorIds: operator2.current,
      };
    }
    if (city.current) {
      filters = {
        ...filters,
        idCity: city.current,
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current,
      };
    }
    if (branch.current) {
      filters = {
        ...filters,
        idBranch: branch.current,
      };
    }
    if (startDateFrom && startDateTo) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(startDateFrom, {
          format: FormatsDate.FULL24,
        }),
        to: formatDateWithCustomFormat(startDateTo, {
          format: FormatsDate.FULL24,
        }),
      };
    }
    reduxGetAddresses(filters);
  }, [currentPage]);

  useEffect(() => {
    setValue('department', {value: "0", label: "Seleccionar"});
    setValue('idCity2', {value: "0", label: "Seleccionar"});
    setValue('idNeighborhood', {value: "0", label: "Seleccionar"});
    reduxSetDepartment();
    reduxSetCity();
    reduxSetNeighborhood();
    setDepartmentFound('');
    setCityFound('');
    setNeighborhoodFound('');
    if (country.current != '' && country.current != undefined && country.current != null) {
      if (rowEdited && rowEdited.boxedAddress != null && rowEdited.boxedAddress.country != country.current) {
        rowEdited.boxedAddress.country = null;
        rowEdited.boxedAddress.department = null;
        rowEdited.boxedAddress.city = null;
        rowEdited.boxedAddress.neighborhood = null;
      }

      const countrySelected =
        countries &&
        Object.keys(countries).length > 0 &&
        countries.items.find((value) => value.idCountry == Number(country.current));
      setCountryFound(countrySelected ? countrySelected.description : '');
      reduxGetDepartmentsByCountry({
        idCountry: Number(country.current),
      });
    }
  }, [country.current]);

  useEffect(() => {
    setValue('idCity2', {value: "0", label: "Seleccionar"});
    setValue('idNeighborhood', {value: "0", label: "Seleccionar"});
    reduxSetCity();
    reduxSetNeighborhood();
    setCityFound('');
    setNeighborhoodFound('');
    if (department.current != '' && department.current != undefined) {
      const departmentSelected =
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        departmentsByCountry.find((value) => value.idDepartment == Number(department.current));
      setDepartmentFound(departmentSelected ? departmentSelected.description : '');

      reduxGetCitiesByDepartment({
        idDepartment: Number(department.current.value),
      });
    }
  }, [department.current]);

  useEffect(() => {
    setValue('idNeighborhood', {value: "0", label: "Seleccionar"});
    reduxSetNeighborhood();
    setNeighborhoodFound('');
    if (city2.current != '' && city2.current != undefined) {
      const citySelected =
        citiesByDepartment &&
        Object.keys(citiesByDepartment).length > 0 &&
        citiesByDepartment.find((value) => value.idCity == Number(city2.current));
      setCityFound(citySelected ? citySelected.description : '');
      reduxGetNeighborhoodsByCity({
        idCity: Number(city2.current.value),
      });
    }
  }, [city2.current]);

  useEffect(() => {
    if (neighborhood.current != '' && neighborhood.current != undefined) {
      const neighborhoodSelected =
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        neighborhoodsByCity.find((value) => value.idNeighborhood == Number(neighborhood.current));
      setNeighborhoodFound(neighborhoodSelected ? neighborhoodSelected.description : '');
    }
  }, [neighborhood.current]);

  useEffect(() => {
    if (checkGenerator) {
      setValue(
        'address',
        `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${crossoverRoad.current} ${crossoverNumber.current ? '#' : ''
        }${crossoverNumber.current}${appendixCrossingNumber.current}${orientationCrossingNumber.current ? ' ' : ''}${orientationCrossingNumber.current
        }${doorNumber.current ? '-' : ''}${doorNumber.current}${insideTypes.current ? ' ' + insideTypes.current + ' ' : ''
        }${inside.current}${neighborhood.current ? ',' : ''} ${neighborhood.current ? neighborhoodFound : ''}${city.current ? ', ' + cityFound : ''
        }${department.current ? ', ' + departmentFound : ''}${country.current ? ', ' + countryFound : ''}`
      );
    }
  }, [
    indicativeRoad.current,
    roadNumber.current,
    appendixRoad.current,
    crossoverRoad.current,
    crossoverNumber.current,
    appendixCrossingNumber.current,
    orientationCrossingNumber.current,
    doorNumber.current,
    insideTypes.current,
    neighborhood.current,
    city2.current,
    department.current,
    country.current,
    cityFound,
    departmentFound,
    countryFound,
    neighborhoodFound,
  ]);

  useEffect(() => {
    if (address || rowUpdated || rowDisabled || addressWithGuide || allGuides) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      reduxGetAddresses(filters);
      reduxResetAddressForm();
      setValue('addressesChecked', null);
      setValue('all', false);
    }
  }, [address, rowUpdated, rowDisabled, addressWithGuide, allGuides]);

  useEffect(() => {
    if (errorsCreateAddress) {
      Swal.fire('Proceso No exitoso!', 'Contactar a soporte@kikilogistics.co', 'error');
    }
  }, [errorsCreateAddress]);

  useEffect(() => {
    if (errorsUpdateAddress) {
      Swal.fire('Proceso No exitoso!', 'Contactar a soporte@kikilogistics.co', 'error');
    }
  }, [errorsUpdateAddress]);

  useEffect(() => {
    if (successfulChangeTrackingIdAddressByOperatorAddress) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };

      if (city.current) {
        filters = {
          ...filters,
          idCity: city.current,
        };
      }

      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      reduxGetAddresses(filters);
      reduxResetChangeTrackingIdAddressByOperator();
      setAddressChangeOperator(null);
      Swal.fire(
        'Proceso exitoso!',
        `Se actualizó el operador ${addressWithChangeTrackingIdAddressByOperator.oldOperator} guía ${addressWithChangeTrackingIdAddressByOperator.oldTrackingId
          ? addressWithChangeTrackingIdAddressByOperator.oldTrackingId
          : ''
        } por Operador ${addressWithChangeTrackingIdAddressByOperator.newOperator}  guía ${addressWithChangeTrackingIdAddressByOperator.newTrackingId
          ? addressWithChangeTrackingIdAddressByOperator.newTrackingId
          : ''
        }.`,
        'success'
      );
    }
  }, [successfulChangeTrackingIdAddressByOperatorAddress]);

  useEffect(() => {
    if (errorsChangeTrackingIdAddressByOperatorAddress) {
      reduxResetChangeTrackingIdAddressByOperator();
      Swal.fire('Proceso no exitoso!', `Contactar a soporte@kikilogistics.co`, 'error');
    }
  }, [errorsChangeTrackingIdAddressByOperatorAddress]);

  useEffect(() => {
    if (successfulCreateBulkTrackingIdAddress) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };

      if (city.current) {
        filters = {
          ...filters,
          idCity: city.current,
        };
      }

      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      reduxGetAddresses(filters);
      reduxResetBulkTrackingIdAddress();
      setTrackingIdsChangeOperator(null);
      Swal.fire(
        'Proceso exitoso!',
        `${bulkTrackingIdOperatorAddress.total} registros seleccionados se asignaron al operador ${bulkTrackingIdOperatorAddress.operator}`,
        'success'
      );
    }
  }, [successfulCreateBulkTrackingIdAddress]);

  useEffect(() => {
    if (errorsCreateBulkTrackingIdAddress) {
      reduxResetBulkTrackingIdAddress();
      Swal.fire('Proceso no exitoso!', `Contactar a soporte@kikilogistics.co`, 'error');
    }
  }, [errorsCreateBulkTrackingIdAddress]);

  useEffect(() => {
    if (fileUploaded) {
      reduxGetAddresses({
        page: currentPage,
        offset: offset,
        search: search,
      });

      if(fileUploaded.invalidItems.length > 0) {
        const token = window.localStorage.getItem("token");
        fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/addresses/invalidOrder`,
          {
            method: "POST",
            body: JSON.stringify(fileUploaded.invalidItems),
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }),
          }
        )
        .then((response) => response.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `unconfirmed_invalids_${DateTime.now().toISODate()}.csv`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
        console.warn(fileUploaded.invalidItems)
      }

      Swal.fire(
        'Proceso exitoso!',
        'Se procesaron ' + fileUploaded.processed + " de "+ fileUploaded.total + ' registros del archivo cargado.',
        'success'
      );
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (fileUploadedTrackingId) {
      reduxGetAddresses({
        page: currentPage,
        offset: offset,
        search: search,
      });
      Swal.fire(
        'Proceso exitoso!',
        'En total se procesaron ' + fileUploadedTrackingId.total + ' registros del archivo cargado.',
        'success'
      );
    }
  }, [fileUploadedTrackingId]);

  useEffect(() => {
    if (addressWithGuide && addressWithGuide.trackingId) {
      Swal.fire('Proceso exitoso!', 'Se generó la guía para la dirección ' + addressWithGuide.idAddress, 'success');
    }
  }, [addressWithGuide]);

  useEffect(() => {
    if (allGuides) {
      Swal.fire(
        'Proceso exitoso!',
        'Se generaron ' + allGuides.updated + ' guías de las ' + allGuides.total + ' seleccionadas.',
        'success'
      );
    }
  }, [allGuides]);

  useEffect(() => {
    if (deleteSoftAddresses) {
      Swal.fire('Proceso exitoso!', 'Se eliminaron ' + deleteSoftAddresses.deleted + ' órdenes.', 'success');
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      if (city.current) {
        filters = {
          ...filters,
          idCity: city.current,
        };
      }
      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      reduxGetAddresses(filters);
      setValue('addressesChecked', null);
      setValue('all', false);
      reduxResetAddressForm();
    }
  }, [deleteSoftAddresses]);

  useEffect(() => {
    if (reversedAddresses) {
      Swal.fire('Proceso exitoso!', 'Se revirtieron ' + reversedAddresses.reversed + ' órdenes.', 'success');
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (client.current) {
        filters = {
          ...filters,
          senderIds: client.current,
        };
      }
      if (operator2.current) {
        filters = {
          ...filters,
          operatorIds: operator2.current,
        };
      }
      if (city.current) {
        filters = {
          ...filters,
          idCity: city.current,
        };
      }
      if (state.current) {
        filters = {
          ...filters,
          state: state.current,
        };
      }
      if (branch.current) {
        filters = {
          ...filters,
          idBranch: branch.current,
        };
      }
      if (startDateFrom && startDateTo) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(startDateFrom, {
            format: FormatsDate.FULL24,
          }),
          to: formatDateWithCustomFormat(startDateTo, {
            format: FormatsDate.FULL24,
          }),
        };
      }
      reduxGetAddresses(filters);
      setValue('addressesChecked', null);
      setValue('all', false);
      reduxResetAddressForm();
    }
  }, [reversedAddresses]);

  useEffect(() => {
    if (
      departmentsByCountry &&
      Object.keys(departmentsByCountry).length > 0 &&
      getValues('manual') &&
      getValues('manual').length > 0
    ) {
      const x = getValues('manual').filter((ad) => {
        if (ad.types[0] === 'administrative_area_level_1') return ad;
      });
      if (x.length > 0) {
        const departmentSelected = departmentsByCountry.filter(
          (ele) =>
            ele.description ==
            String(x[0].long_name)
              .replace('á', 'a')
              .replace('é', 'e')
              .replace('í', 'i')
              .replace('ó', 'o')
              .replace('ú', 'u')
              .toUpperCase()
        );
        if (departmentSelected.length > 0) {
          setValue('department', departmentSelected[0].idDepartment);
        }
      }
    } else {
      if (
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        rowEdited &&
        rowEdited.boxedAddress != null
      ) {
        setValue('department', rowEdited.boxedAddress.department);
      } else if (
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        rowEdited &&
        rowEdited.stateNameReceiver != null
      ) {
        setValue(
          'department',
          departmentsByCountry.filter((de) => de.description === rowEdited.stateNameReceiver)[0].idDepartment
        );
      }
    }
  }, [departmentsByCountry]);

  useEffect(() => {
    if (
      citiesByDepartment &&
      Object.keys(citiesByDepartment).length > 0 &&
      getValues('manual') &&
      getValues('manual').length
    ) {
      const x = getValues('manual').filter((ad) => {
        if (ad.types[0] === 'administrative_area_level_2') return ad;
      });
      if (x.length > 0) {
        const citySelected = citiesByDepartment.filter(
          (ele) =>
            ele.description ==
            String(x[0].long_name)
              .replace('á', 'a')
              .replace('é', 'e')
              .replace('í', 'i')
              .replace('ó', 'o')
              .replace('ú', 'u')
              .toUpperCase()
        );
        if (citySelected.length > 0) {
          setValue('idCity2', citySelected[0].idCity);
        }
      }
    } else {
      if (
        citiesByDepartment &&
        Object.keys(citiesByDepartment).length > 0 &&
        rowEdited &&
        rowEdited.boxedAddress != null
      ) {
        setValue('idCity2', rowEdited.boxedAddress.city);
      }
      if (citiesByDepartment && Object.keys(citiesByDepartment).length > 0 && rowEdited && rowEdited.idCity != null) {
        setValue('idCity2', rowEdited.idCity);
      }
    }
  }, [citiesByDepartment]);

  useEffect(() => {
    if (successfulUploadInvalidAddresses) {
        Swal.fire(
            'Proceso exitoso!',
            'Se procesaron ' + fetchInvalidAddressesData.processed + " de "+ fetchInvalidAddressesData.total + ' registros del archivo cargado.',
            'success'
        );
    }
}, [successfulUploadInvalidAddresses]);

  useEffect(() => {
    if (
      neighborhoodsByCity &&
      Object.keys(neighborhoodsByCity).length > 0 &&
      getValues('manual') &&
      getValues('manual').length
    ) {
      const x = getValues('manual').filter((ad) => {
        if (ad.types[0] === 'neighborhood') return ad;
      });
      if (x.length > 0) {
        const neighborhoodSelected = neighborhoodsByCity.filter(
          (ele) =>
            ele.description ==
            String(x[0].long_name)
              .replace('á', 'a')
              .replace('é', 'e')
              .replace('í', 'i')
              .replace('ó', 'o')
              .replace('ú', 'u')
              .replace('Barrio ', '')
              .toUpperCase()
        );
        if (neighborhoodSelected.length > 0) {
          setValue('idNeighborhood', neighborhoodSelected[0].idNeighborhood);
        }
      }
    } else {
      if (
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        rowEdited &&
        rowEdited.boxedAddress != null
      ) {
        setValue('idNeighborhood', rowEdited.boxedAddress.neighborhood);
      }
      if (
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        rowEdited &&
        rowEdited.neighborhoodNameReceiver != null
      ) {
        setValue(
          'idNeighborhood',
          neighborhoodsByCity.filter((ne) => ne.description === rowEdited.neighborhoodNameReceiver)[0].idNeighborhood
        );
      }
    }
  }, [neighborhoodsByCity]);

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Direcciones</h1>
                <div>
                  <Show when='feature:create-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Crear dirección')}>
                      <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleShow}>
                        <i className='fas fa-plus fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:upload-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Cargar direcciones')}>
                      <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleShowUpload}>
                        <i className='fas fa-upload fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones erróneas')}>
                      <button
                        className='btn btn-danger btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(1)}>
                        <i className='fas fa-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-address-without-guide'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones sin guía')}>
                      <button
                        className='btn btn-danger btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(2)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-all-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar histórico')}>
                      <button
                        className='btn btn-info btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(3)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:update-upload-invalid-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Cargar direcciones inválidas')}>
                      <button
                        className='btn btn-warning btn-circle'
                        type='button'
                        onClick={handleShowUploadUpdateAddress}>
                        <i className='fas fa-upload fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-invalid-address'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar direcciones inválidas')}>
                      <button
                        className='btn btn-warning btn-circle'
                        type='button'
                        onClick={(e) => openModalDates(4)}>
                        <i className='fas fa-file-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
              </div>
              <p className='mb-4'>Módulo de Administración de direcciones</p>
              <div className='card shadow mb-4'>
                <div className='card-header py-3'>
                  <h6 className='m-0 font-weight-bold text-primary'>Listado de Direcciones</h6>
                </div>
                <div className='card-body'>
                  <Row>
                    <Col md={2.5} className='mx-3'>
                      <label>Desde</label>
                      <br />
                      <DatePicker
                        selected={startDateFrom}
                        onChange={(date) => handledChangeFrom(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                      />
                    </Col>
                    <Col md={2.5} >
                      <label>Hasta</label>
                      <br />
                      <DatePicker
                        selected={startDateTo}
                        onChange={(date) => handledChangeTo(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        minDate={startDateFrom}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                        filterTime={(time) => {
                          if (!startDateFrom) {
                            return true;
                          }
                          const selectedTime = startDateFrom.getTime();
                          const currentTime = time.getTime();
                          return currentTime >= selectedTime;
                        }}
                      />
                    </Col>
                    <Col>
                      <Show when='feature:show-client-address'>
                        <label
                          htmlFor='senderIds'
                          className='form-label'>
                          Remitente
                        </label>
                        <Select
                          {...register('senderIds')}
                          id='senderIds'
                          onChange={(value) => handleChangeMultiSelect('Sender', value)}
                          isClearable={true}
                          isDisabled={!startDateFrom || !startDateTo}
                          isMulti
                          styles={customStyles}
                          options={
                            clients && Object.keys(clients).length > 0
                              ? clients.items
                                .filter((f) => f.isActive && (viewAllCountriesData || f.country == countryUser))
                                .map((e, key) => ({
                                  value: e.idCompany,
                                  label: e.description,
                                }))
                              : []
                          }
                        />
                      </Show>
                    </Col>
                    <Col>
                      <Show when='feature:show-operator-address'>
                        <label
                          htmlFor='operatorIds'
                          className='form-label'>
                          Operador
                        </label>
                        <Select
                        {...register('operatorIds')}
                        id='operatorIds'
                        onChange={(value) => handleChangeMultiSelect('Operator', value)}
                        isClearable={true}
                        isMulti
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true && (viewAllCountriesData || f.country == countryUser))
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                      </Show>
                    </Col>
                    <Col>
                      <label
                        htmlFor='state'
                        className='form-label'>
                        Estado
                      </label>
                      <Select
                        isClearable={true}
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        {...register('state')}
                        id='state'
                        onChange={(value) => setValue('state', value?.value || '')}
                        options={
                          statuses && Object.keys(statuses).length > 0
                            ? [
                              ...statuses.items.map((ele) => ({
                                value: ele.code,
                                label: ele.name,
                              })),
                              { value: '-2', label: 'Eliminados' },
                            ]
                            : []
                        }
                      />
                    </Col>
                    <Col>
                      <Show when='feature:show-branch'>
                      <label
                        htmlFor='branch'
                        className='form-label'>
                        Bodega
                      </label>
                      <Select
                        isClearable={true}
                        isDisabled={!startDateFrom || !startDateTo}
                        styles={customStyles}
                        {...register('branch')}
                        id='branch'
                        onChange={(value) => setValue('branch', value?.value || '')}
                        options={
                          branches && Object.keys(branches).length > 0
                            ? branches.items
                                .filter((b) => viewAllCountriesData || b.country.idCountry == countryUser)
                                .map((ele) => ({
                                  value: ele.idBranch,
                                  label: ele.branchName,
                                }))
                            : []
                        }
                      />
                      </Show>
                    </Col>
                    <Col>
                      <button className='btn btn-primary' onClick={searchByFilter} style={{marginTop: "32px"}}>Buscar</button>
                    </Col>
                  </Row>
                  <br />
                  <Grid
                    className='stickyAddress'
                    cols={columns}
                    data={addresses && Object.keys(addresses).length > 0 ? addresses.items : []}
                    total={addresses && addresses.hasOwnProperty('total') ? addresses.total : 0}
                    page={addresses && Object.keys(addresses).length > 0 ? Number(addresses.page) : currentPage}
                    pages={addresses && Object.keys(addresses).length > 0 ? Number(addresses.totalPages) : 0}
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (client.current) {
                        filters = {
                          ...filters,
                          senderIds: client.current,
                        };
                      }
                      if (operator2.current) {
                        filters = {
                          ...filters,
                          operatorIds: operator2.current,
                        };
                      }
                      if (city.current) {
                        filters = {
                          ...filters,
                          idCity: city.current,
                        };
                      }
                      if (state.current) {
                        filters = {
                          ...filters,
                          state: state.current,
                        };
                      }
                      if (branch.current) {
                        filters = {
                          ...filters,
                          idBranch: branch.current,
                        };
                      }
                      if (startDateFrom && startDateTo) {
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(startDateFrom, {
                            format: FormatsDate.FULL24,
                          }),
                          to: formatDateWithCustomFormat(startDateTo, {
                            format: FormatsDate.FULL24,
                          }),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (client.current) {
                        filters = {
                          ...filters,
                          senderIds: client.current,
                        };
                      }
                      if (operator2.current) {
                        filters = {
                          ...filters,
                          operatorIds: operator2.current,
                        };
                      }
                      if (city.current) {
                        filters = {
                          ...filters,
                          idCity: city.current,
                        };
                      }
                      if (state.current) {
                        filters = {
                          ...filters,
                          state: state.current,
                        };
                      }
                      if (branch.current) {
                        filters = {
                          ...filters,
                          idBranch: branch.current,
                        };
                      }
                      if (startDateFrom && startDateTo) {
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(startDateFrom, {
                            format: FormatsDate.FULL24,
                          }),
                          to: formatDateWithCustomFormat(startDateTo, {
                            format: FormatsDate.FULL24,
                          }),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size='xl'
          show={showTimeline}
          onHide={handleCloseTimeline}
          onEscapeKeyDown={handleCloseTimeline}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Timeline idAddress={idAddressSelected}></Timeline>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          size='xl'
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop='static'
          keyboard={true}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>{rowEdited ? 'Editar' : 'Nueva'} Dirección</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='trackingId'
                      className='form-label'>
                      Guía
                    </label>
                    <input
                      id='trackingId'
                      type='text'
                      className={`form-control form-control-user ${errors.trackingId && 'is-invalid'}`}
                      {...register('trackingId', {
                        pattern: {
                          value: /^[a-zA-Z0-9]*$/i,
                          message: 'invalid guide',
                        },
                      })}
                      disabled={rowEdited ? true : false}
                    />
                    {errors.trackingId && <span className='invalid-feedback'>La guía no es válida</span>}
                  </div>
                  <div className='form-group form-group col-md-3'>
                    <label
                      htmlFor='firstName'
                      className='form-label'>
                      Nombre*
                    </label>
                    <input
                      id='firstName'
                      type='text'
                      className={`form-control form-control-user ${errors.firstName && 'is-invalid'}`}
                      {...register('firstName', {
                        required: true,
                        pattern: {
                          value: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+((\s[a-zA-ZñÑáéíóúÁÉÍÓÚ]+){1}){0,1}$/i,
                          message: 'invalid name',
                        },
                      })}
                    />
                    {errors.firstName && (
                      <span className='invalid-feedback'>El nombre es requerido y/o no es válido</span>
                    )}
                  </div>
                  <div className='form-group form-group col-md-3'>
                    <label
                      htmlFor='lastName'
                      className='form-label'>
                      Apellido*
                    </label>
                    <input
                      id='lastName'
                      type='text'
                      className={`form-control form-control-user ${errors.lastName && 'is-invalid'}`}
                      {...register('lastName', {
                        required: true,
                        pattern: {
                          value: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+((\s[a-zA-ZñÑáéíóúÁÉÍÓÚ]+){1}){0,1}$/i,
                          message: 'invalid surnames',
                        },
                      })}
                    />
                    {errors.lastName && (
                      <span className='invalid-feedback'>El apellido es requerido y/o no es válido</span>
                    )}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='reference1'
                      className='form-label'>
                      Teléfono Destinatario*
                    </label>
                    <input
                      id='reference1'
                      type='text'
                      className={`form-control form-control-user ${errors.reference1 && 'is-invalid'}`}
                      {...register('reference1', {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z0-9]+$/i,
                          message: 'invalid reference 1',
                        },
                      })}
                    />
                    {errors.reference1 && (
                      <span className='invalid-feedback'>La referencia 1 es requerida y/o no es válida</span>
                    )}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='reference2'
                      className='form-label'>
                      Observaciones
                    </label>
                    <input
                      id='reference2'
                      type='text'
                      className={`form-control form-control-user ${errors.reference2 && 'is-invalid'}`}
                      {...register('reference2', {
                        pattern: {
                          value: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]*$/i,
                          message: 'invalid reference 2',
                        },
                      })}
                    />
                    {errors.reference2 && <span className='invalid-feedback'>La referencia 2 no es válida</span>}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='deliveryDate'
                      className='form-label'>
                      Fecha de Entrega
                    </label>
                    <input
                      id='deliveryDate'
                      type='date'
                      className={`form-control form-control-user`}
                      {...register('deliveryDate')}
                      min={DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd')}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='senderEmail'
                      className='form-label'>
                      Correo remitente
                    </label>
                    <input
                      id='senderEmail'
                      type='email'
                      className={`form-control form-control-user ${errors.senderEmail && 'is-invalid'}`}
                      {...register('senderEmail', {
                        pattern: {
                          value: /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})?$/i,
                          message: 'invalid email',
                        },
                      })}
                      placeholder='nombre@servidor.dominio'
                    />
                    {errors.senderEmail && <span className='invalid-feedback'>El correo no es válido</span>}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='senderPhone'
                      className='form-label'>
                      Teléfono remitente
                    </label>
                    <input
                      id='senderPhone'
                      type='number'
                      className={`form-control form-control-user ${errors.senderPhone && 'is-invalid'}`}
                      {...register('senderPhone', {
                        pattern: {
                          value: /^[0-9]*$/i,
                          message: 'invalid phone',
                        },
                      })}
                    />
                    {errors.senderPhone && <span className='invalid-feedback'>El teléfono no es válido</span>}
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12 m12'>
                    <GoogleMapsAutocomplete
                      id={'manualAddress'}
                      required={!checkGenerator && !rowEdited}
                      value={valueManualAddress}
                      onChange={(data) => {
                        setValueManualAddress(
                          data.address_components
                            .map((ad) => {
                              return ad.long_name;
                            })
                            .join(', ')
                        );
                        data.address_components
                          ? setValue('manual', data.address_components)
                          : setValue('manual', data);
                        setValue('country', null);
                        setValue('department', '');
                        setValue('idCity2', '');
                        setValue('idNeighborhood', '');
                        reduxSetDepartment();
                        reduxSetCity();
                        reduxSetNeighborhood();
                        const x = data.address_components.filter((ad) => {
                          if (ad.types[0] === 'country') return ad;
                        });
                        if (x.length > 0) {
                          const countrySelected = countries.items.filter(
                            (ele) =>
                              ele.description ==
                              String(x[0].long_name)
                                .replace('á', 'a')
                                .replace('é', 'e')
                                .replace('í', 'i')
                                .replace('ó', 'o')
                                .replace('ú', 'u')
                                .toUpperCase()
                          );
                          if (countrySelected.length > 0) {
                            setValue('country', countrySelected[0].idCountry);
                            reduxGetDepartmentsByCountry({
                              idCountry: countrySelected[0].idCountry,
                            });
                          }
                        }
                      }}
                      disabled={checkGenerator}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12 m12'>
                    <div
                      className='form-group col-md-3'
                      style={{ top: '20px' }}>
                      <label htmlFor='generateAddress'>
                        <input
                          id='generateAddress'
                          type='checkbox'
                          {...register('generateAddress')}
                          style={{ marginRight: '10px' }}
                          onChange={(e) => checkOption(e)}
                        />
                        Generador de direcciones
                      </label>
                    </div>
                  </div>
                </div>
                {rowEdited && (
                  <div className='row'>
                    <div className='col s12 m12'>
                      <h6 className='m-0 font-weight-bold text-primary'>Dirección actual</h6>
                      <input
                        id='currentAddress'
                        readOnly='true'
                        type='text'
                        {...register('currentAddress')}
                        className={`form-control`}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    border: '1px solid #e3e6f0',
                    borderRadius: '0.35rem ',
                    marginBottom: '10px',
                  }}>
                  {checkGenerator && (
                    <>
                      <div
                        className='row'
                        style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <div className='col s12 m6'>
                          <div className='form-group'>
                            <label
                              htmlFor='address'
                              className='form-label'>
                              Generador de direcciones
                            </label>
                            <input
                              {...register('address', { required: true })}
                              readOnly={true}
                              id='address'
                              type='text'
                              className={`form-control`}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{ marginLeft: '5px' }}>
                        <div className='col s12 m12'>
                          <div className='mb-2'>
                            <h6 className='m-0 font-weight-bold text-primary'>Vía</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          border: '1px solid #e3e6f0',
                          borderRadius: '0.35rem ',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='indicativeRoad'
                            className='form-label'>
                            Indicativo de la vía*
                          </label>
                          <InputList
                            id={'indicativeRoad'}
                            type={SelectConstEnabled._addressRoadType}
                            required={checkGenerator && !rowEdited}
                            value={getValues('indicativeRoad')}
                            name='El indicativo de la vía'></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='roadNumber'
                            className='form-label'>
                            Numero de la vía*
                          </label>
                          <input
                            {...register('roadNumber', {
                              required: checkGenerator && !rowEdited,
                              pattern: {
                                value: /^[a-zA-Z0-9]+$/i,
                                message: 'invalid road number',
                              },
                            })}
                            id='roadNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.roadNumber && 'is-invalid'}`}
                          />
                          {errors.roadNumber && (
                            <span className='invalid-feedback'>El número de la vía es requerido y/o no es válido</span>
                          )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='appendixRoad'
                            className='form-label'>
                            Apéndice
                          </label>
                          <InputList
                            id={'appendixRoad'}
                            type={SelectConstEnabled._addressNomenclature}
                            value={getValues('appendixRoad')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='crossoverRoad'
                            className='form-label'>
                            Orientación
                          </label>
                          <InputList
                            id={'crossoverRoad'}
                            type={SelectConstEnabled._addressCardinal}
                            value={getValues('crossoverRoad')}
                            required={false}></InputList>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{ marginLeft: '5px', marginTop: '5px' }}>
                        <div className='col s12 m12'>
                          <div className='mb-2'>
                            <h6 className='m-0 font-weight-bold text-primary'>Cruce</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          border: '1px solid #e3e6f0',
                          borderRadius: '0.35rem ',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='crossoverNumber'
                            className='form-label'>
                            Numero de cruce*
                          </label>
                          <input
                            {...register('crossoverNumber', {
                              required: checkGenerator && !rowEdited,
                              pattern: {
                                value: /^[a-zA-Z0-9]+$/i,
                                message: 'invalid crossover number',
                              },
                            })}
                            id='crossoverNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.crossoverNumber && 'is-invalid'}`}
                          />
                          {errors.crossoverNumber && (
                            <span className='invalid-feedback'>El número de cruce es requerido y/o no es válido</span>
                          )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='appendixCrossingNumber'
                            className='form-label'>
                            Apéndice
                          </label>
                          <InputList
                            id={'appendixCrossingNumber'}
                            type={SelectConstEnabled._addressNomenclature}
                            value={getValues('appendixCrossingNumber')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='orientationCrossingNumber'
                            className='form-label'>
                            Orientación
                          </label>
                          <InputList
                            id={'orientationCrossingNumber'}
                            type={SelectConstEnabled._addressCardinal}
                            value={getValues('orientationCrossingNumber')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='doorNumber'
                            className='form-label'>
                            Numero de la puerta*
                          </label>
                          <input
                            {...register('doorNumber', {
                              required: checkGenerator && !rowEdited,
                              pattern: {
                                value: /^[a-zA-Z0-9]+$/i,
                                message: 'invalid door number',
                              },
                            })}
                            id='doorNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.doorNumber && 'is-invalid'}`}
                          />
                          {errors.doorNumber && (
                            <span className='invalid-feedback'>
                              El número de la puerta es requerido y/o no es válido
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          marginLeft: '5px',
                          marginTop: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='insideTypes'
                            className='form-label'>
                            Tipos de Interior
                          </label>
                          <InputList
                            id={'insideTypes'}
                            type={SelectConstEnabled._addressInsideType}
                            value={getValues('insideTypes')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='inside'
                            className='form-label'>
                            Interior
                          </label>
                          <input
                            {...register('inside', {
                              pattern: {
                                value: /^[a-zA-Z0-9]*$/i,
                                message: 'invalid inside',
                              },
                            })}
                            id='inside'
                            type='text'
                            className={`form-control ${errors.inside && 'is-invalid'}`}
                          />
                          {errors.inside && <span className='invalid-feedback'>El interior no es válido</span>}
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className='row'
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}>
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='country'
                        className='form-label'>
                        Pais*
                      </label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        {...register('country', { required: !rowEdited })}
                        id='country'
                        onChange={(value) => setValue('country', value?.value || '')}
                        options={
                          countries && Object.keys(countries).length > 0
                            ? [
                              ...countries.items.map((ele) => ({
                                value: ele.idCountry,
                                label: ele.description,
                              })),
                            ]
                            : []
                        }
                      />
                      {((!rowEdited && errors.country && getValues('country') == '') ||
                        (rowEdited && errors.country && getValues('country') == '')) && (
                          <span className='invalid-feedback'>El pais es requerido</span>
                        )}
                    </div>
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='department'
                        className='form-label'>
                        Departamento*
                      </label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        {...register('department', { required: !rowEdited })}
                        id='department'
                        value={getValues("department")}
                        onChange={(value) => setValue('department', {value: value?.value, label: value?.label})}
                        options={
                          departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                            ? [
                              ...departmentsByCountry.map((ele) => ({
                                value: ele.idDepartment,
                                label: ele.description,
                              })),
                            ]
                            : []
                        }
                      />
                      {((!rowEdited &&
                        (errors.department || errors.country || errors.idCity2) &&
                        getValues('department') == '') ||
                        (rowEdited &&
                          (errors.department || errors.country || errors.idCity2) &&
                          getValues('department') == '')) && (
                          <span className='invalid-feedback'>El departamento es requerido</span>
                        )}
                    </div>
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='idCity2'
                        className='form-label'>
                        Ciudad*
                      </label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        {...register('idCity2', { required: !rowEdited })}
                        id='idCity2'
                        value={getValues("idCity2")}
                        onChange={(value) => setValue('idCity2', {value: value?.value, label: value?.label})}
                        options={
                          citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                            ? [
                              ...citiesByDepartment.map((ele) => ({
                                value: ele.idCity,
                                label: ele.description,
                              })),
                            ]
                            : []
                        }
                      />

                      {((!rowEdited &&
                        (errors.department || errors.country || errors.idCity2) &&
                        getValues('idCity2') === '') ||
                        (rowEdited &&
                          (errors.department || errors.country || errors.idCity2) &&
                          getValues('idCity2') === '')) && (
                          <span className='invalid-feedback'>La ciudad es requerida</span>
                        )}
                    </div>
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='neighborhood'
                        className='form-label'>
                        Barrio
                      </label>

                      <Select
                        isClearable={true}
                        styles={customStyles}
                        value={getValues("idNeighborhood")}
                        {...register('idNeighborhood', { required: !rowEdited })}
                        id='idNeighborhood'
                        onChange={(value) => setValue('idNeighborhood', {value: value?.value, label: value?.label})}
                        options={
                          neighborhoodsByCity && Object.keys(neighborhoodsByCity).length > 0
                            ? [
                              ...neighborhoodsByCity.map((ele) => ({
                                value: ele.idNeighborhood,
                                label: ele.description,
                              })),
                            ]
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='clientTrackingId'
                      className='form-label'>
                      Guía del Cliente*
                    </label>
                    <input
                      id='clientTrackingId'
                      type='number'
                      className={`form-control form-control-user ${errors.clientTrackingId && 'is-invalid'}`}
                      {...register('clientTrackingId', {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: 'invalid client guide',
                        },
                      })}
                      min={0}
                      defaultValue={0}
                    />
                    {errors.clientTrackingId && (
                      <span className='invalid-feedback'>La guía de cliente es requerida y/o no es válido</span>
                    )}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='externalId'
                      className='form-label'>
                      Id Externo*
                    </label>
                    <input
                      id='externalId'
                      type='number'
                      className={`form-control form-control-user ${errors.externalId && 'is-invalid'}`}
                      {...register('externalId', {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: 'invalid external Id',
                        },
                      })}
                      min={0}
                      defaultValue={0}
                    />
                    {errors.externalId && (
                      <span className='invalid-feedback'>El id externo es requerido y/o no es válido</span>
                    )}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='declaredValue'
                      className='form-label'>
                      Valor declarado
                    </label>
                    <input
                      disabled={rowEdited}
                      id='declaredValue'
                      type='text'
                      className={`form-control form-control-user ${errors.declaredValue && 'is-invalid'}`}
                      {...register('declaredValue', {
                        pattern: {
                          value: /^[0-9]*$/i,
                          message: 'invalid declared value',
                        },
                      })}
                    />
                    {errors.declaredValue && <span className='invalid-feedback'>El valor declarado no es válido</span>}
                  </div>
                  <div className='form-group col-md-3'>
                    <label
                      htmlFor='collection'
                      className='form-label'>
                      Valor a recaudar
                    </label>
                    <input
                      disabled={rowEdited}
                      id='collection'
                      type='text'
                      className={`form-control form-control-user ${errors.collection && 'is-invalid'}`}
                      {...register('collection', {
                        pattern: {
                          value: /^[0-9]*$/i,
                          message: 'invalid collection',
                        },
                      })}
                    />
                    {errors.collection && <span className='invalid-feedback'>El valor a recaudar no es válido</span>}
                  </div>
                  {session && session.role.idCompany == null && (
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='idCompany'
                        className='form-label'>
                        Empresa*
                      </label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        {...register('idCompany', { required: true })}
                        id='idCompany'
                        onChange={(value) => setValue('idCompany', value?.value || '')}
                      options={
                        clients && Object.keys(clients).length > 0
                          ? [
                            ...clients.items
                            .filter((f) => f.isActive === true)
                            .map((e) => ({
                              value: e.idCompany,
                              label: e.description,
                            })),
                          ]
                          : []
                      }
                    />
                      {errors.idCompany && <span className='invalid-feedback'>La empresa es requerida</span>}
                    </div>
                  )}
                  {!(hasPermission('feature:isOperator') || hasPermission('feature:isClient')) && (
                    <div className='form-group col-md-3'>
                      <label
                        htmlFor='idOperator'
                        className='form-label'>
                        Operador*
                      </label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        {...register('idOperator', { required: true })}
                        id='idOperator'
                        onChange={(value) => setValue('idOperator', value?.value || '')}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                      {errors.idOperator && <span className='invalid-feedback'>El operador es requerido</span>}
                    </div>
                  )}
                  {rowEdited && (
                    <div
                      className='form-group col-md-3'
                      style={{ top: '40px' }}>
                      <label htmlFor='generateGuide'>
                        <input
                          id='generateGuide'
                          type='checkbox'
                          {...register('generateGuide')}
                          style={{ marginRight: '10px', marginLeft: '10px' }}
                        />
                        Generar guía
                      </label>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={handleClose}>
                  Cancelar
                </Button>
                <Button
                  variant='primary'
                  type='submit'>
                  Guardar
                </Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      </div>
      <div>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          onEscapeKeyDown={handleCloseUpload}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {session && session.role.idCompany == null && (
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label
                      htmlFor='idCompanyModal'
                      className='form-label'>
                      Remitente
                    </label>
                    <Select
                        {...registerUpload('idCompanyModal', { required: true })}
                        isClearable={true}
                        id="idCompanyModal"
                        onChange={(value) => setValueUpload('idCompanyModal', value?.value || '')}
                        className={`${errorsUpload.idCompanyModal && 'is-invalid'}`}
                        options={
                          clients && Object.keys(clients).length > 0
                            ? clients.items
                              .filter((f) => f.isActive)
                              .map((e, key) => ({
                                value: e.idCompany,
                                label: e.description,
                              }))
                            : []
                        }
                        styles={{
                          menu: (baseStyles) => ({...baseStyles, zIndex: 9999})
                        }}
                      />
                    {errorsUpload.idCompanyModal && <span className='invalid-feedback'>El remitente es requerido</span>}
                  </div>
                </div>
              )}
              {!(hasPermission('feature:isOperator') || hasPermission('feature:isClient')) && (
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label
                      htmlFor='idOperator'
                      className='form-label'>
                      Operador
                    </label>
                    <Select
                        {...registerUpload('idOperator', { required: true })}
                        isClearable={true}
                        className={`${errorsUpload.idOperator && 'is-invalid'}`}
                        id="idOperator"
                        onChange={(value) => setValueUpload('idOperator', value?.value || '')}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                        styles={{
                          menu: (baseStyles) => ({...baseStyles, zIndex: 9999})
                        }}
                      />
                    {errorsUpload.idOperator && <span className='invalid-feedback'>El operador es requerido</span>}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="file" className="form-label">
                    Archivo
                  </label>
                  <div className="custom-file">
                    <input
                      type='file'
                      id='file'
                      name='file'
                      {...registerUpload('file', { required: true })}
                      className={`custom-file-input ${errorsUpload.file && 'is-invalid'}`}
                    />{' '}
                    <label
                      className='custom-file-label'
                      htmlFor='validatedCustomFile'>
                      {fileError ? fileError : fileName ? fileName : 'Seleccionar archivo...'}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseUpload}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>



      <div>
        <Modal
          show={showUpdateAddress}
          onHide={handleCloseUpdateAddress}
          onEscapeKeyDown={handleCloseUpdateAddress}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitUpdateAddress(onSubmitUploadUpdateInvalidAddress)}>
            <Modal.Header closeButton>
              <Modal.Title>Actualizar direcciones erroneas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="file" className="form-label">
                    Archivo
                  </label>
                  <div className="custom-file">
                    <input
                      type='file'
                      id='file'
                      name='file'
                      {...registerUpdateAddress('file', { required: true })}
                      className={`custom-file-input ${errorsUploadUpdateAddress.file && 'is-invalid'}`}
                    />{' '}
                    <label
                      className='custom-file-label'
                      htmlFor='validatedCustomFile'>
                      {fileErrorUpdateAddress ? fileErrorUpdateAddress : fileNameUpdateAddress ? fileNameUpdateAddress : 'Seleccionar archivo...'}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseUpdateAddress}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      <div>
        <Modal
          show={showUploadTrackingId}
          onHide={handleCloseUploadTrackingId}
          onEscapeKeyDown={handleCloseUploadTrackingId}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitUploadTrackingId(onSubmitUploadTrackingId)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className='form-group col-md-12'>
                  <div className='custom-file'>
                    <input
                      type='file'
                      id='file'
                      name='file'
                      {...registerUploadTrackingId('file', { required: true })}
                      className={`custom-file-input ${errorsUploadTrackingId.file && 'is-invalid'}`}
                    />{' '}
                    <label
                      className='custom-file-label'
                      htmlFor='validatedCustomFile'>
                      {fileErrorTrackingId
                        ? fileErrorTrackingId
                        : fileNameTrackingId
                          ? fileNameTrackingId
                          : 'Seleccionar archivo...'}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseUploadTrackingId}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          size='lg'
          show={showModalDates}
          onHide={() => {
            setShowModalDates(false);
          }}
          onEscapeKeyDown={() => setShowModalDates(false)}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>
              {buttonType === 1
                ? 'Descargar direcciones erróneas'
                : buttonType === 2
                  ? 'Descargar direcciones sin guía'
                  : buttonType === 3
                    ? 'Descargar histórico'
                    : 'Descargar direcciones invalidas'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProvider>
              <Form onSubmit={handleSubmitDownload(handleDownload)}>
                <Row>
                  <Form.Group as={Col}>
                    <div>Desde</div>
                    <Controller
                      control={downloadFormControl}
                      name='startDate'
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          required
                          selected={value}
                          onChange={onChange}
                          showTimeSelect={buttonType === 3}
                          customInput={<CustomInput className={`${downloadFormErrors.startDate && 'is-invalid'}`} />}
                          peekNextMonth
                          dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                          maxDate={new Date()}
                          timeIntervals={10}
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <div>Hasta</div>
                    <Controller
                      control={downloadFormControl}
                      name='endDate'
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          required
                          selected={value}
                          onChange={onChange}
                          showTimeSelect={buttonType === 3}
                          customInput={<CustomInput className={`${downloadFormErrors.endDate && 'is-invalid'}`} />}
                          peekNextMonth
                          dateFormat={buttonType === 3 ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
                          maxDate={new Date()}
                          minDate={downloadFormWatchValue('startDate')}
                          timeIntervals={10}
                          filterTime={(time) => {
                            const dateStart = downloadFormWatchValue('startDate');
                            if (!dateStart) {
                              return true;
                            }
                            const selectedTime = dateStart.getTime();
                            const currentTime = time.getTime();
                            return currentTime >= selectedTime;
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                {
                  buttonType === 3 &&
                  <Show whenNot={['feature:isClient', 'feature:isOperator']}>
                    <Row>
                      <Form.Group as={Col}>
                        <div>Remitente</div>
                        <Controller
                          name='senders'
                          control={downloadFormControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                              isMulti
                              styles={customStyles}
                              // styles={
                              //   {
                              //     ...customStyles,
                              //     container: (baseStyles) => ({ ...baseStyles, width: '20vw' })
                              //   }
                              // }
                              options={
                                clients && Object.keys(clients).length > 0
                                  ? clients.items
                                    .filter((f) => f.isActive)
                                    .map((e, key) => ({
                                      value: e.idCompany,
                                      label: e.description,
                                    }))
                                  : []
                              }
                            />
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                      <div>País</div>  
                      <Controller
                          name='country'
                          control={downloadFormControl}
                          render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                          isClearable={true}
                          styles={customStyles}
                          options={
                            countries && Object.keys(countries).length > 0
                              ? countries.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idCountry,
                                  label: ele.description,
                                }))
                              : []
                          }
                          />
                        )}
                      />
                    </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                      <div>Operador</div>  
                      <Controller
                          name='operator'
                          control={downloadFormControl}
                          render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={!downloadFormWatchValue('startDate') || !downloadFormWatchValue('endDate')}
                          styles={customStyles}
                          isClearable={true}
                          options={
                            logisticsOperators && Object.keys(logisticsOperators).length > 0
                              ? logisticsOperators.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                              : []
                          }
                          />
                        )}
                      />
                    </Form.Group>
                    </Row>
                  </Show>
                }
                <Modal.Footer>
                  <button
                    dis
                    className='btn  btn-primary float-right'
                    type='submit'>
                    Descargar <i className='fas fa-solid fa-download'></i>
                  </button>
                </Modal.Footer>
              </Form>
            </FormProvider>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          show={showChangeState}
          onHide={handleCloseChangeState}
          onEscapeKeyDown={handleCloseChangeState}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitChangeState(onSubmitChangeState)}>
            <Modal.Header closeButton>
              <Modal.Title>{'Cambiar estado'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='col s12 m6'>
                <div className='form-group'>
                  <label
                    htmlFor='stateChangeState'
                    className='form-label'>
                    Estado
                  </label>
                  <select
                    {...registerChangeState('stateChangeState', {
                      required: true,
                    })}
                    id='stateChangeState'
                    name='stateChangeState'
                    className={`custom-select ${errorsChangeState.stateChangeState && 'is-invalid'}`}>
                    <option value={''}>Seleccionar…</option>
                    <option value={4}>En tránsito a ciudad destino</option>
                    <option value={11}>Alistamiento</option>
                    <option value={15}>Producto Perdido</option>
                    <option value={16}>Producto Dañado</option>
                  </select>
                  {errorsChangeState.stateChangeState && (
                    <span className='invalid-feedback'>El estado es requerido</span>
                  )}
                </div>
                <input
                  id='noteChangeState'
                  placeholder='Escriba comentario'
                  type='text'
                  className={`form-control form-control-user`}
                  {...registerChangeState('noteChangeState')}
                  style={{ marginTop: '5px', marginBottom: '10px' }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseChangeState}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      <div>
        <Modal
          show={showChangeOperator}
          onHide={handleCloseChangeOperator}
          onEscapeKeyDown={handleCloseChangeOperator}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitChangeOperator(onSubmitChangeOperator)}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar operador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className='form-group col-md-12'>
                  <label
                    htmlFor='idOperator'
                    className='form-label'>
                    Operador
                  </label>
                  <select
                    {...registerChangeOperator('idOperator', {
                      required: true,
                    })}
                    id='idOperator'
                    className={`custom-select ${errorsChangeOperator.idOperator && 'is-invalid'}`}>
                    <option value={''}>Seleccionar…</option>
                    {
                      logisticsOperators &&
                      Object.keys(logisticsOperators).length > 0 &&
                      logisticsOperators.items
                        .filter((f) => f.isActive == true)
                        .map((ele, key) => (
                          <option
                            key={key}
                            value={ele.idCompany}>
                            {ele.description}
                          </option>
                        ))
                    }
                  </select>
                  {errorsChangeOperator.idOperator && (
                    <span className='invalid-feedback'>El operador es requerido</span>
                  )}
                </div>
                <div className='form-group col-md-12'>
                  <label
                    htmlFor='idBranch'
                    className='form-label'>
                    Sucursal
                  </label>
                  <select
                    {...registerChangeOperator('idBranch', { required: true })}
                    id='idBranch'
                    className={`custom-select ${errorsChangeOperator.idBranch && 'is-invalid'}`}>
                    <option value={''}>Seleccionar…</option>
                    {branches &&
                      Object.keys(branches).length > 0 &&
                      branches.items
                        .filter((f) => f.isActive == true)
                        .map((ele, key) => (
                          <option
                            key={key}
                            value={ele.idBranch}>
                            {ele.branchName}
                          </option>
                        ))}
                  </select>
                  {errorsChangeOperator.idBranch && <span className='invalid-feedback'>La sucursal es requerida</span>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseChangeOperator}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.companyState.clients,
    logisticsOperators: state.companyState.logisticsOperators,
    statuses: state.statusesState.statuses,
    addresses: state.addressState.addresses,
    address: state.addressState.address,
    rowEdited: state.addressState.rowEdited,
    rowDeleted: state.addressState.rowDeleted,
    rowUpdated: state.addressState.rowUpdated,
    fileUploaded: state.addressState.fileUploaded,
    fileUploadedTrackingId: state.addressState.fileUploadedTrackingId,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    zoneNeighborhoods: state.zoneNeighborhoodState.zoneNeighborhoods,
    rowDisabled: state.addressState.rowDisabled,
    addressWithGuide: state.addressState.addressWithGuide,
    allGuides: state.addressState.allGuides,
    routesCourier: state.routeState.routesCourier,
    addressState: state.addressState.addressState,
    pdfRespondeByKikilogistics: state.pdfTrackingState.pdfRespondeByKikilogistics,
    successfulCreateBulkTrackingIdAddress: state.addressState.successfulCreateBulkTrackingIdAddress,
    bulkTrackingIdOperatorAddress: state.addressState.bulkTrackingIdOperatorAddress,
    errorsCreateBulkTrackingIdAddress: state.addressState.errorsCreateBulkTrackingIdAddress,
    successfulChangeTrackingIdAddressByOperatorAddress:
    state.addressState.successfulChangeTrackingIdAddressByOperatorAddress,
    errorsChangeTrackingIdAddressByOperatorAddress: state.addressState.errorsChangeTrackingIdAddressByOperatorAddress,
    addressWithChangeTrackingIdAddressByOperator: state.addressState.addressWithChangeTrackingIdAddressByOperator,
    branches: state.branchState.branches,
    deleteSoftAddresses: state.addressState.deleteSoftAddresses,
    reversedAddresses: state.addressState.reversedAddresses,
    rowReversed: state.addressState.rowReversed,
    successfulUploadInvalidAddresses: state.addressState.successfulUploadInvalidAddresses,
    fetchInvalidAddressesData: state.addressState.fetchInvalidAddressesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
    dispatch({
      type: "FETCH_NEW_STATUS_REQUEST",
      value: payload,
    }),
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetZoneNeighborhoods: (payload) =>
      dispatch({
        type: 'FETCH_ZONENEIGHBORHOODS_REQUEST',
        value: payload,
      }),
    reduxGetAddresses: (payload) =>
      dispatch({
        type: 'FETCH_ADDRESSES_REQUEST',
        value: payload,
      }),
    reduxPostAddress: (payload) =>
      dispatch({
        type: 'CREATE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGetAddress: (payload) =>
      dispatch({
        type: 'READ_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxDeleteAddress: (payload) =>
      dispatch({
        type: 'DELETE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxPatchAddress: (payload) =>
      dispatch({
        type: 'UPDATE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxResetAddressForm: () =>
      dispatch({
        type: 'RESET_ADDRESS_FORM',
      }),
    reduxUploadFile: (payload) =>
      dispatch({
        type: 'UPLOAD_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxUpdateAddressFile: (payload) =>
      dispatch({
        type: 'UPDATE_UPLOAD_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxDownloadInvalidAddresses: (payload) =>
      dispatch({
        type: 'FETCH_INVALID_ADDRESSES',
        value: payload,
      }),
    reduxResetAddressFormTrackingId: () =>
      dispatch({
        type: 'RESET_TRACKING_ID_FORM',
      }),
    reduxUploadFileTrackingId: (payload) =>
      dispatch({
        type: 'UPLOAD_TRACKING_ID_REQUEST',
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: 'FETCH_CITIES_REQUEST',
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: 'FETCH_COUNTRIES_REQUEST',
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: 'READBYDEPARTMENT_CITY_REQUEST',
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: 'RESET_BYCOUNTRY_DEPARTMENT',
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: 'RESET_BYDEPARTMENT_CITY',
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: 'RESET_BYCITY_NEIGHBORHOOD',
        value: payload,
      }),
    reduxDisableAddress: (payload) =>
      dispatch({
        type: 'DISABLE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGenerateGuide: (payload) =>
      dispatch({
        type: 'GENERATE_GUIDE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGenerateAllGuides: (payload) =>
      dispatch({
        type: 'GENERATE_ALL_GUIDES_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxPatchRoutesCourier: (payload) =>
      dispatch({
        type: 'UPDATECOURIER_ROUTE_REQUEST',
        value: payload,
      }),
    reduxPatchAddressState: (payload) =>
      dispatch({
        type: 'UPDATESTATE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxResetAddressesEvents: () =>
      dispatch({
        type: 'RESET_READ_BY_IDADDRESS_ADDRESSES_EVENT',
      }),
    reduxPostBulkTrackingIdAddress: (payload) =>
      dispatch({
        type: 'CREATE_BULK_TRACKINGID_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxResetBulkTrackingIdAddress: (payload) =>
      dispatch({
        type: 'CREATE_BULK_TRACKINGID_ADDRESS_RESET',
      }),

    reduxChangeTrackingIdAddressByOperator: (payload) =>
      dispatch({
        type: 'GHANGE_OPERATOR_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxResetChangeTrackingIdAddressByOperator: (payload) =>
      dispatch({
        type: 'GHANGE_OPERATOR_ADDRESS_RESET',
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: 'FETCH_BRANCHES_REQUEST',
        value: payload,
      }),
    reduxDeleteAddresses: (payload) =>
      dispatch({
        type: 'DELETE_ADDRESSES_REQUEST',
        value: payload,
      }),
    reduxReverseAddresses: (payload) =>
      dispatch({
        type: 'REVERSE_ADDRESSES_REQUEST',
        value: payload,
      }),
    reduxReverseAddress: (payload) =>
      dispatch({
        type: 'REVERSE_ADDRESS_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
